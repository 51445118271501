<script setup>
    const {selectedTab, items} = defineProps({
        selectedTab: {
            type: String,
        },
        items: {
            type: Object,
            default: {
                id: null
            }
        }
    })
    console.log({selectedTab})
</script>

<template>
<div>
    <div class="d-flex m-t-2 m-b-5">
        <div
            :class="{'b-primary text-primary': name===selectedTab, 'b-gray-lighter': name !==selectedTab, 'text-gray-lighter-50': items.length<1}"
            class="m-x-1 p-x-2 b-b-1 cursor-pointer f-1 text-center"
            @click="callback"
            v-for="(callback, name) in items">{{ name.toUpperCase() }}
        </div>
    </div>
    <slot></slot>
</div>
</template>