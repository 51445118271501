<template>
  <section>
    <div class="grid md:grid-9-3 " >
      <div class="water-bg h-65vh position-relative" :style="dropStyle">
        <h1 class="text-gray position-relative" style="text-align: center; top:10vh">{{ boatModel.name }}</h1>
        <div class="grid h-80p m-x-10" style="margin: auto 0"  ref="waterContainer">
          <div class="place-y-center p-y-3 p-x-7" :style="flooring" v-click-outside="onClickOutside">
            <div class="d-flex">
              <div v-for="(number,i) in grid" class="b-white-50 position-relative bg-gray-50 b-t-2 b-l-2 b-b-2 cursor-pointer"
                   :class="{'b-r-2': i === grid.length-1,'first-item':i===0, 'item':i>0}">
                <div class="position-relative h-100p" @click.prevent="activeStep>0||selectedModules.zero ? activeStep=i : activeStep=0" :class="{'enlarged':activeStep===i}">
                  <div class="position-relative h-100p" v-if="selectedModules[numberKeys[i]]">
                    <img :src="orgamaxImage(selectedModules[numberKeys[i]])" alt="module" class="h-100p"
                         :title="selectedModules[numberKeys[i]].title">
                    <div v-if="activeStep===i"
                         class="circle font-md text-warning bg-white-50 cursor-pointer position-absolute grid"
                         style="top:3px; right:3px;width: 1.5rem; height: 1.5rem" @click.prevent="removeModule(i)">
                      <i class="las la-minus-circle place-x-center place-y-center" title="remove"></i>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="grid-4-4-4 position-absolute bg-white-50 p-x-3 p-t-2 p-b-2 b-white"
             style="top: 0; right: 3px; width: 50%; ">
          <div>Sleeps</div>
          <div>Kitchen</div>
          <div>Bathroom</div>
          <div class="font-md">
            {{ occupancy }}
          </div>
          <div class="font-md">
            <i class="las la-check-circle text-success" v-if="checkCategory('kitchen')"></i>
            <i class="las la-times-circle" v-if="!checkCategory('kitchen')"></i>
          </div>
          <div class="font-md">
            <i class="las la-check-circle text-success" v-if="checkCategory('bath')"></i>
            <i class="las la-times-circle" v-if="!checkCategory('bath')"></i>
          </div>
        </div>
      </div>

      <div class="m-l-2 p-3 d-flex md:h-65vh" style="flex-direction: column; overflow-y: auto">
        <selection-header :current-step="4" :steps="6" :back-to="'/interior'"/>
        <total-runner :step="4" class="f-1"/>
        <bottom-nav to="/driving" back-to="/interior" :next-condition="complete"/>
      </div>

    </div>
    <div >
        <div class="p-x-2 cursor-pointer text-center font-lg">Create your interior configuration</div>
        <super-tab :items="supertabItems" :selected-tab="selectedSuperTab">
            <tab-shop v-model:selected-tab="moduleDisplay" :multi-items="modules" @update="shopSelection" :hide-headline="true">
            </tab-shop>
        </super-tab>

    </div>

  </section>
</template>
<style>
.ghost {
  color: transparent;
}

.first-item {
  width: 105px;
  height: 134px;
}

.item {
  width: 78px;
  height: 134px;
}

@media screen and (min-width: 1920px) {
  .first-item {
    width: 210px;
    height: 268px;
  }

  .item {
    width: 156px;
    height: 268px;
  }
}

.enlarged {
  transform: scale(1.2);
  background: rgba(255, 255, 255, .3);
  z-index: 2;
}

.circle {
  border-radius: 50%;
}


</style>
<script>
import stern from '@/assets/modules/stern.png'
import bedroom from '@/assets/modules/bedroom.png'
import kitchen from '@/assets/modules/kitchen.png'
import multipurpose from '@/assets/modules/multipurpose.png'
import balcony from '@/assets/modules/balcony.png'
import living from '@/assets/modules/living.png'
import water from '@/assets/water.png'
import flooring from '@/assets/flooring-x3.png'
import api from "@/api";
import selectionHeader from "@/components/selectionHeader";
import totalRunner from "@/components/totalRunner"
import TabShop from "@/components/TabShop"
import {orgamaxImage} from "@/composables/orgamax";
import BottomNav from "@/components/BottomNav";
import vClickOutside from 'click-outside-vue3'
import SuperTab from "@/components/SuperTab.vue";

export default {
  name: 'Modules',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
      SuperTab,
    selectionHeader,
    totalRunner,
    TabShop,
    BottomNav
  },
  data: () => ({
    activeStep: 0,
    bedroomCount: 3,
    drag: false,
    numberKeys: ['zero', 'one', 'two', 'three', 'four', 'five', 'six'],
    selectedModules: {},
    remoteModules: [],
    boatModel: {},
    categories: [],
    modules: {
      stern: [],
      sleep: [],
      bath: [],
      kitchen: [],
      living: [],
      balcony: [],
    },
    moduleDisplay: 'stern',
    grid: [],
    flooring: {
      backgroundImage: 'url(' + flooring + ')',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      margin: '0 auto',
      width: '80%'
    },
    selectedSuperTab: null,
    supertabItems: {}
  }),

  async mounted() {
      this.superTabSelection();

    // boat model
    this.boatModel = this.$store.state.boatModel;

    this.grid = this.numberKeys.slice(0, this.boatModel.num_modules);
    this.calculateFlooring();
    window.addEventListener('resize', () => {
      this.calculateFlooring();
    });
    this.setup();

  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.calculateFlooring();
      console.log('fire')
    });
  },
  watch: {
    bedroomCount(newVal) {
      this.grid = this.numberKeys.slice(0, Number(this.boatModel.num_modules));
    },
    activeStep() {
      this.drop()
    }
  },
  computed: {
    complete() {
      let completed = Object.keys(this.$store.state.selectedModules).length !== 0;
      Object.keys(this.$store.state.selectedModules).forEach(key => {
        if (this.$store.state.selectedModules[key] === null) {
          completed = false;
        }
      })
      return completed;
    },
    unitStyle() {
      return {
        // minWidth: 100/this.boatModel.modules +'%',
        minWidth: '10%',
        height: '25vh'
      }
    },
    dropStyle() {
      return {
        backgroundImage: 'url(' + water + ')',
        backgroundSize: 'cover'
      }

    },

    lastPreviousCategory() {
      if (typeof this.previousCategories !== 'undefined') {
        return this.previousCategories[this.previousCategories.length - 1]
      }
      return '';
    },
    previousCategories() {
      let categories = [];
      this.numberKeys.forEach(num => {
        if (this.selectedModules[num]) {
          categories = this.selectedModules[num].categories;
        }
      })
      return categories;
    },
    isLastStep() {
      return (this.activeStep + 1) - this.boatModel.num_modules === 0
    },
    requiresLivingModule() {
      if (!this.selectedModules[this.numberKeys[this.activeStep - 1]]) {
        return false;
      }
      const last = this.selectedModules[this.numberKeys[this.activeStep - 1]];
      return last.article_module_types.findIndex( x => x.section === 'kitchen') !== -1;

    },
    occupancy() {
      let sleeps = 0;
      this.numberKeys.forEach(num => {
        if (typeof this.selectedModules[num] !== 'undefined' && this.includesCategory(this.selectedModules[num],'sleep')) {
          sleeps += 2;
        }
      })
      return sleeps;
    },

  },
  methods: {
    orgamaxImage,
    setup(){
        this.numberKeys.forEach((num, i) => {
            if (i < this.boatModel.num_modules) {
                this.selectedModules[num] = this.$store.state.selectedModules[num] || null;
            }
        })
        this.modules.stern = this.filterModulesByCategory('stern');
    },
    superTabSelection(){
        // superTab
        this.$store.getters.interior.forEach(interior => {
            this.supertabItems[interior.title] = () => {
                this.$store.commit('setInterior', interior)
                this.superTabSelection();
                this.setup()
                this.drop()
            }
        })
        this.selectedSuperTab = this.$store.state.interior.title;

        // modules
        this.remoteModules = this.$store.getters.modules(this.$store.state.interior.title)
    },
    onClickOutside(ev){
      if(ev.target===this.$refs.waterContainer){
        this.activeStep = 11;
      }
    },
    shopSelection(selection){
      this.selectedModules[this.numberKeys[this.activeStep]] = selection.item;
      this.$store.commit('setSelectedModules', this.selectedModules);
      if(this.activeStep < this.boatModel.num_modules-1){
        this.activeStep++
      }
      this.suggestedCategory()
      this.drop()
    },
    calculateFlooring() {
      if (this.$el.clientWidth < 1903) {
        this.flooring.width = 105 + (this.boatModel.num_modules - 1) * 80 + 'px'
      } else {
        this.flooring.width = 210 + (this.boatModel.num_modules - 1) * 156 + 'px'
      }
    },
    assign(element) {
      this.selectedModules[this.numberKeys[this.activeStep]] = element;
      if (this.activeStep < this.boatModel.num_modules) {
        this.activeStep++;
      }
      this.drop()
    },

    filterModulesByCategory(category) {
      return this.remoteModules.filter(x => typeof x.article_module_types.find(y => y.section === category) !== 'undefined' )
    },
    checkCategory(category) {
      let found = false;
      this.numberKeys.forEach(num => {
        if (this.selectedModules[num] && this.includesCategory(this.selectedModules[num], category)) {
          found = true;
        }
      })
      return found;
    },
    suggestedCategory(){
      switch (this.activeStep) {
        case 0:
          this.moduleDisplay = 'stern';
          break;
        case 1:

          this.moduleDisplay = this.boatModel.num_modules > 4 ? 'sleep' : 'bath';
          break;
        case 2:
          this.moduleDisplay = this.boatModel.num_modules > 4 ? 'bath' : 'kitchen';
          break;
        case 3:
          this.moduleDisplay = this.boatModel.num_modules > 4 ? 'kitchen' : 'balcony';
          break;
        case 4:
          this.moduleDisplay = this.boatModel.num_modules > 5 ? 'living' : 'balcony';
          break;
        case 5:
          this.moduleDisplay = 'balcony';
          break;
        default:
          this.moduleDisplay = 'living';
      }
    },
    includesCategory(module, category){
      return module && typeof module.article_module_types.find(x => x.section === category) !== "undefined";
    },
    drop(ev) {
      this.$store.commit('setSelectedModules', this.selectedModules)
      this.moduleDisplay = 'stern';
      this.modules = {
        stern: [],
        sleep: [],
        bath: [],
        kitchen: [],
        living: [],
        balcony: [],
      };
      if (this.selectedModules.zero) {
        if (this.activeStep - this.$store.state.boatModel.num_modules <= 0) {
          const previous = this.selectedModules[this.numberKeys[this.activeStep - 1]];
          if (previous && this.includesCategory(previous, 'balcony') ) {
            this.selectedModules[this.numberKeys[this.boatModel.num_modules - 1]] = this.$store.state.remoteModules.find(x => x.title.toLowerCase().includes('extended unit 2'));
            this.activeStep++;
            this.drop();
            return;
          }
          if(this.activeStep===0){
            this.modules.stern = this.filterModulesByCategory('stern');
            return;
          }
          const allModules = this.$store.getters.modules(this.$store.state.interior.title).filter(remoteModule => {

            // bedroom
            if(!this.isLastStep && this.includesCategory(remoteModule, 'sleep') && !this.includesCategory(remoteModule,'kitchen') && !this.includesCategory(remoteModule,'stern')){
              return true;
            }

            // living
            if(!this.isLastStep && this.includesCategory(remoteModule, 'living')){
              return true;
            }

            // bath
            if(!this.isLastStep && this.includesCategory(remoteModule, 'bath')){
              return true;
            }

            // kitchen
            if(!this.isLastStep && this.includesCategory(remoteModule, 'kitchen')){
              return true;
            }

            // multipurpose
            if(!this.isLastStep && !this.requiresLivingModule && this.includesCategory(remoteModule, 'multipurpose')){
              return true;
            }


            // balconies


            if (this.activeStep + 2 >= this.boatModel.num_modules && this.includesCategory(remoteModule, 'balcony') && !this.requiresLivingModule) {

              if (remoteModule.title.toLowerCase().includes('extended unit 2')) {
                return false;
              }
              if (remoteModule.title.toLowerCase().includes('extended unit 1') && this.isLastStep) {
                return false;
              }
              if (remoteModule.title.toLowerCase().includes('single unit') && !this.isLastStep) {
                return false;
              }
              return true;
            }
            return false;
          })
          allModules.forEach(module => {
            ['sleep', 'balcony', 'kitchen', 'living', 'bath'].forEach(type => {
              if (this.includesCategory(module,type)) {
                this.modules[type].push(module)
              }
            })
          })
          this.suggestedCategory()

        }
      } else {
        this.modules.stern = this.filterModulesByCategory('stern');
      }
    },
    categoryIdToString(categoryId) {
      const remoteCategory = this.categories.find(x => x._id === categoryId);
      return remoteCategory.slug;
    },
    countOccurrence(specifiedType) {
      let count = 0;
      const remoteCategory = this.categories.find(x => x.slug === specifiedType);
      Object.keys(this.selectedModules).forEach(key => {
        this.selectedModules[key].forEach(module => {
          if (module['type-2'] === remoteCategory._id) {
            count++;
          }
        })
      })
      return count;
    },
    removeModule(key) {
      // balcony?
      if (this.selectedModules[this.numberKeys[key]].balcony && typeof this.selectedModules[this.numberKeys[key + 1]] !== 'undefined') {
        this.selectedModules[this.numberKeys[key + 1]] = null
      }
      this.selectedModules[this.numberKeys[key]] = null;
      this.$store.commit('setSelectedModules', this.selectedModules);
      this.drop()
    },
    reload() {
      window.location.reload()
    },
    undo() {
      this.selectedModules[this.numberKeys[this.activeStep - 1]] = [];
      this.drop()
    }
  }
}
</script>

<style scoped>

</style>